import { ExcelService, PdfService } from 'src/app/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomSnackBarComponent } from 'src/app/shared/components/custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from 'src/app/shared/components/custom-snackbar-delete/custom-snackbar-delete.component';
import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';

const helper = {
  primaryColor: '#3c26bf',
  //for chart
  chartColor1: '#563FD9',
  chartColor2: '#6955DD',
  chartColor3: '#6955DD',
  chartColor4: '#FFC533',
  chartColor5: '#2F1D95',
  chartColor6: '#8F7FE6',
  //for snackbar
  // Updated by Ovais Siddiqui with ref to ticket NPD-1116 on 06/06/2022
  duration: 3000,
  hPosition: 'center',
  vPosition: 'top',
  //permissions
  permission: {
    add: false,
    delete: false,
    edit: false,
    export: false,
    print: false,
    view: false,
  },
  //to validate fields on submit
  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control); //{6}
      }
    });
  },
  /* permission w.r.t role*/
  permissions(routeDat: any) {
    const user_type_short_name = localStorage.getItem('user_type_short_name');
    if (user_type_short_name == 'SF') {
      const routeData = routeDat._value;
      if (routeData.module != 'dashboard' && routeData.module != undefined) {
        return this.checkPermissions(routeData);
      }
    } else {
      return {
        add: true,
        delete: true,
        edit: true,
        export: true,
        print: true,
        view: true,
      };
    }
  },
  /* permission w.r.t features/module */
  checkPermissions(routeData: any) {
    const menuItems = JSON.parse(localStorage.getItem('menuitems') as string);
    if (
      routeData.feature == undefined &&
      !menuItems.find(
        (x) => x.name.toUpperCase() == routeData.module.toUpperCase(),
      )
    ) {
      return this.permission;
    }
    if (
      routeData.feature != undefined &&
      routeData.feature != 'dashboard' &&
      !menuItems.find((x) =>
        x.features.some(
          (item) => item.name.toUpperCase() === routeData.feature.toUpperCase(),
        ),
      )
    ) {
      return this.permission;
    } else {
      return menuItems
        .find((x) => x.name.toUpperCase() == routeData.module.toUpperCase())
        .features.find(
          (item) => item.name.toUpperCase() === routeData.feature.toUpperCase(),
        ).permissions.permissions;
    }
  },

  setDefaultImage(profileImage) {
    if (Object.keys(profileImage).length == 0) {
      profileImage.url =
        environment.baseUrl + '/assets/images/default_profile.jpeg';
      //   } else {
      //     profileImage.url =
      //       environment.apiHostName + "/assets/images/default_profile.jpeg";
    }
    return profileImage;
  },

  snackBarMessageFn(message, panelClass, action, _snackBar) {
    const snackBarActionComponent =
      action === 'delete'
        ? CustomSnackbarDeleteComponent
        : CustomSnackBarComponent;
    _snackBar.openFromComponent(snackBarActionComponent, {
      data: { message: message },
      horizontalPosition: 'center',
      duration: 2000,
      panelClass: [panelClass],
      verticalPosition: 'top',
    });
  },

  removeEmptyFields(data: any): any {
    const cleanedData = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        cleanedData[key] = value;
      }
    });

    return cleanedData;
  },

  removeFormArrayEmptyFields(data: any[]): any[] {
    const cleanedDataArray = data
      .map((item) => {
        const cleanedData = {};

        Object.entries(item).forEach(([key, value]) => {
          if (value !== null && value !== undefined && value !== '') {
            cleanedData[key] = value;
          }
        });

        return cleanedData;
      })
      .filter((item) => Object.keys(item).length > 0);

    return cleanedDataArray.length > 0 ? cleanedDataArray : [];
  },
  getBranchesfromAllClasses(allClasses) {
    const branchArray = allClasses.data.list.map((el) => el.branch);
    const seen = new Set();
    const filteredArr = branchArray.filter((el) => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return !duplicate;
    });
    return filteredArr;
  },
  getCurrentAcademicTerm(data) {
    const today = new Date();

    return (
      data.find((item) => {
        const startDate = new Date(item.start);
        const endDate = new Date(item.end);
        return startDate <= today && today <= endDate;
      }) || null
    );
  },

  showSnackBar(type, message, _snackBar) {
    if (type === 'delete') {
      _snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
        data: { message: message },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['delete-snackBar'],
        verticalPosition: 'top',
      });
    } else if (type === 'success') {
      _snackBar.openFromComponent(CustomSnackBarComponent, {
        data: { message: message },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['save-snackBar'],
        verticalPosition: 'top',
      });
    }
  },
};

class Helper {
  pdfService: PdfService;
  excelService: ExcelService;
  constructor(pdfService: PdfService, excelService: ExcelService) {
    this.pdfService = pdfService;
    this.excelService = excelService;
  }
  /**
   * Function to create pdf table to download circular records
   */
  createPdf(tableHeadings, excelData, pdfHeading, tableOptions) {
    const heading = [];
    const values = [];
    const body = [];

    tableHeadings.forEach((item) => {
      heading.push({
        text: item,
        style: 'tableHeader',
      });
    });
    const pdfArray = JSON.parse(JSON.stringify(excelData));
    // var pdfArray = this.apiData
    for (const item of pdfArray) {
      // eslint-disable-next-line no-var
      for (var k in item) {
        if (item[k] == '') item[k] = '-';
        if (!item[k]) delete item[k];

        if (!pdfHeading.includes(k)) {
          delete item[k];
        }

        if (typeof item[k] == 'object') {
          const nestedObjOfPdfArrayItemArr = ['name', 'url'];
          nestedObjOfPdfArrayItemArr.map((i) => {
            if (Object.keys(item[k]).includes(i)) {
              item[k] = item[k][i];
            }
          });
        }
      }
    }
    pdfArray.forEach((item) => {
      values.push(Object.values(item));
    });
    body.push(heading);
    for (let i = 0; i < values.length; i++) {
      const element = values[i];
      body.push(element);
    }

    tableOptions.download = true;
    this.pdfService.generatePdfTable(
      tableOptions.pdfTitle,
      tableOptions.subTitle,
      body,
      tableOptions,
    );
  }

  exportexcel(title, heading, excelData) {
    // let outerArray = [];
    const sendBody = [];

    const newArray = JSON.parse(JSON.stringify(excelData));
    for (let i = 0; i < newArray.length; i++) {
      const innerArray = [];
      for (const k in newArray[i]) {
        if (newArray[i][k] !== undefined) {
          innerArray.push(newArray[i][k]);
        }
      }
      sendBody.push(innerArray);
    }

    const subTitleForExcel =
      title + ` list for the year of ${localStorage.getItem('academic_year')}`;
    const footerText =
      'Downloaded By:  ' +
      localStorage.getItem('firstName') +
      ' at' +
      ' ' +
      new Date().toLocaleString();
    const pdfName =
      localStorage.getItem('schoolName') +
      '-' +
      title +
      '-' +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear();
    this.excelService.generateExcel(
      subTitleForExcel,
      heading,
      sendBody,
      pdfName,
      footerText,
    ); //this.tableOptions.footerText)
    // this.excelService.exportAsExcelFile(this.excelData, this.title);
  }
}

/**
 * Get specific item(s) from Local Storage
 * @returns local storage item(s)
 */
const getFromLocalStorage = {
  currentAcademicYearID: () => localStorage.getItem('academic_year_id'),
  currentUserType: localStorage.getItem('user_type'),
};

const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
  }
  document.body.removeChild(textArea);
};

class Utility {
  private hexToRGB(hexCode) {
    const hex = hexCode.slice(1);
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    return { r, g, b };
  }

  private getLuminance(rgb) {
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;
    const gammaCorrect = (c) =>
      c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4;
    return (
      0.2126 * gammaCorrect(r) +
      0.7152 * gammaCorrect(g) +
      0.0722 * gammaCorrect(b)
    );
  }

  getContrastColorByHexCode(hexCode) {
    const rgb = this.hexToRGB(hexCode);
    const luminance = this.getLuminance(rgb);
    return luminance > 128 ? '#000000' : '#FFFFFF';
  }
}

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const domainRegex = /^(?!-)[A-Za-z0-9-]+(?<!-)(\.[A-Za-z]{2,})+$/;

export {
  Helper,
  helper,
  getFromLocalStorage,
  unsecuredCopyToClipboard,
  Utility,
  emailRegex,
  domainRegex,
};
