import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-admission-shared-footer',
  templateUrl: './admission-shared-footer.component.html',
  styleUrls: ['./admission-shared-footer.component.scss'],
})
export class AdmissionSharedFooterComponent {
  showFooter = false;
  @Input() addNotes: any;

  viewAllNotes(): void {
    this.showFooter = !this.showFooter;
  }
}
