import { AcademicTermService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import {
  CustomSnackBarComponent,
  CustomSnackbarDeleteComponent,
  Utility,
  helper,
} from 'src/app/shared';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { BillingService } from 'src/app/core/services/billing/billing.service';
import { FormControl } from '@angular/forms';
import { FlexibleTimetableService } from 'src/app/core/services/timetable/flexible-timetable/flexible-timetable.service';
import { InvoiceService } from 'src/app/core/services/billing/invoice/invoice.service';
import { MatSnackBar } from '@angular/material';
import { MeetingLogsService } from 'src/app/core';
import { ParentUserService } from 'src/app/core/services/parent/parent-user/parent-user.service';
import { StructureTimetableService } from 'src/app/core/services/timetable/structured-Timetable/timetable/structure-timetable.service';
import { StudentEventsService } from 'src/app/core/services/student/student-events/student-events.service';
import { StudentProfileService } from 'src/app/core/services/student/student-profile/student-profile.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { UserType } from '../../enums-constant/user-type.enum';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss'],
  providers: [Utility],
})
export class StudentProfileComponent implements OnInit {
  @Input() hideStudentProfileBillingCard = false;
  loading$ = new Subject<boolean>();
  userProfileId = '';
  studentProfile = false;
  overView = true;
  timeTableView = false;
  meetingLogView = false;
  qualification = false;
  workExperience = false;
  parentExist = false;
  lettersView = false;
  passwordhide = true;
  studentProfData;
  siblingInfo: any;
  billingInfo: any;
  loading = false;
  isAdminView = false;
  isStaffUserView = false;
  isTeacherView = false;
  isParentView = false;
  isStudentView = false;
  breadcrumb = [];
  totalNoOfDays = 0;
  totalPresent = 0;
  totalAbsent = 0;
  percentage = '';
  attendanceData = true;
  studentId: any;
  meetingLogsData = [];
  url = '';
  editID: any;
  courses = [];
  securityDeposit = 0;
  withdrawDetails;
  stdCreditionals;
  color;
  loadingExtendDueDate = '';
  showtimetable = false;
  timetableData = [];
  showTimeTable = false;
  loadingFlexibleTimetable = false;
  flexibleTimetableData = [];
  assignTerm = new FormControl(undefined);
  academicTerms = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public invoiceService: InvoiceService,
    public meetingLogsService: MeetingLogsService,
    private studentProfileService: StudentProfileService,
    private billingService: BillingService,
    private studentService: StudentEventsService,
    private studentParentService: StudentEventsService,
    private parentUserService: ParentUserService,
    private utility: Utility,
    public _snackBar: MatSnackBar,
    private StructureTimetable: StructureTimetableService,
    private flexibleTimetableService: FlexibleTimetableService,
    private analyticsService: AnalyticsService,
    private academicTermService: AcademicTermService,
  ) {}

  ngOnInit() {
    this.getAcademicTerms();
    const userType = localStorage.getItem('user_type');
    this.isAdminView = userType === UserType.Admin;
    this.isParentView = userType === UserType.Parent;
    this.isStudentView = userType === UserType.Student;
    this.isStaffUserView = userType === UserType.Staff;
    this.isTeacherView = userType === UserType.Teacher;
    this.userProfileId = this.route.snapshot.paramMap.get('id');
    this.getStudentProfile(this.userProfileId);
    this.getAllMeetingLogs();
    if (this.isParentView) this.getGmailCreditionals();

    this.url = this.router.url;
    if (this.isAdminView || this.isStaffUserView) {
      this.breadcrumb = [
        {
          label: 'Home',
          url: '/admin',
        },
        {
          label: 'Student',
          url: '/admin/student/student-dashboard',
        },
        {
          label: 'Students',
          url: '/admin/student/students',
        },
        {
          label: 'Profile',
          url: '',
        },
      ];
    }
    if (this.isStudentView) {
      this.breadcrumb = [
        {
          label: 'Home',
          url: '/student',
        },
        // {
        //   label: '',
        //   url: '',
        // },
        // {
        //   label: '',
        //   url: '',
        // },
      ];
    }
    if (this.isParentView) {
      this.breadcrumb = [
        {
          label: 'Home',
          url: '/parent',
        },
        // {
        //   label: '',
        //   url: '',
        // },
        // {
        //   label: '',
        //   url: '',
        // },
      ];
    }

    this.route.queryParams.subscribe((params) => {
      if (params['showMeetingLogs']) {
        this.viewMeetingLog();
      }
    });
  }
  getAcademicTerms() {
    this.academicTermService.get().subscribe((res: any) => {
      this.academicTerms = res.data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    });
  }

  getBillingInfo(rollNo) {
    this.billingService.getBillingForProfile(rollNo).subscribe((res: any) => {
      this.billingInfo = res.data[0];
      this.withdrawDetails = res.withdrawRequest;
      this.securityDeposit = res.securityDeposit;
    });
  }
  viewStudentInformation() {
    this.studentProfile = true;
    this.overView = false;
    this.timeTableView = false;
    this.meetingLogView = false;
    this.lettersView = false;
  }

  viewProfileOverview() {
    this.studentProfile = false;
    this.overView = true;
    this.timeTableView = false;
    this.meetingLogView = false;
    this.lettersView = false;
  }

  viewTimeTable() {
    this.studentProfile = false;
    this.overView = false;
    this.meetingLogView = false;
    this.timeTableView = true;
    this.lettersView = false;
    this.studentId = this.userProfileId;
    this.getStructureTimetable();
    this.getStudentFlexibleTimetable();
  }

  viewMeetingLog() {
    this.studentProfile = false;
    this.overView = false;
    this.timeTableView = false;
    this.meetingLogView = true;
    this.lettersView = false;
  }

  viewLetters() {
    this.studentProfile = false;
    this.overView = false;
    this.timeTableView = false;
    this.meetingLogView = false;
    this.lettersView = true;
  }

  nextQualification() {
    this.studentProfile = false;

    this.qualification = true;
    this.workExperience = false;
  }

  nextWorkExperience() {
    this.studentProfile = false;

    this.qualification = false;
    this.workExperience = true;
  }

  getStudentProfile(id) {
    this.studentProfileService.getByID(id).subscribe(async (res: any) => {
      this.courses = res.data.extraCurricular;
      if (res.data.house) {
        this.color = this.utility.getContrastColorByHexCode(
          res.data.house.color,
        );
      }
      if (Object.keys(res.data.studentParent).length !== 0) {
        this.parentExist = true;
        if (res.data.studentParent.studentParent != undefined) {
          this.siblingInfo =
            res.data.studentParent.studentParent.studentProfile.filter(
              (element) =>
                element.studentProfile != null &&
                element.studentProfile._id != this.userProfileId,
            );
        } else {
          if (res.data.studentParent.studentParent === null) {
            this.parentExist = false;
          } else {
            this.parentExist = true;
          }
          this.siblingInfo = [];
        }
      } else {
        this.parentExist = false;
      }
      this.studentProfData = res.data;
      await this.getBillingInfo(this.studentProfData.rollNo);
    });
  }

  getAllMeetingLogs() {
    this.loading = true;
    const params = `limit=2&studentId=${this.userProfileId}`;
    this.meetingLogsService
      .getStudentMeetingLogs(params)
      .subscribe((res: any) => {
        this.meetingLogsData = res.data.filter((log) => !!log);
        this.loading = false;
      });
  }

  edit(id) {
    if (this.isAdminView || this.isStaffUserView) {
      this.router.navigateByUrl('admin/student/student-main/' + id);
      this.editID = id;
    }
    if (this.isStudentView) {
      this.router.navigateByUrl('student/edit-profile/' + id);
      this.editID = id;
    }
  }

  delete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.studentProfileService.delete(id).subscribe((res: any) => {
          if (res.status == 'success') {
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: 'Student has been deleted!' },
              horizontalPosition: 'center',
              duration: 2000,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
          }

          this.router.navigateByUrl('/admin/student/students');
        });
      }
    });
  }

  viewProfile(id) {
    const redirectUrl = this.url.replace(this.userProfileId, id);
    this.userProfileId = id;
    this.router.navigateByUrl(redirectUrl).then((page) => {
      window.location.reload();
    });
    this.getStudentProfile(id);
  }
  viewParentProfile(parentId, studentId?, isMotherSelected?) {
    let redirectUrl = '';
    if (this.isAdminView || this.isStaffUserView) {
      redirectUrl = `admin/parent/parentprofile/${parentId}`;
    }
    if (this.isParentView) {
      redirectUrl = `parent/profile/${parentId}`;
    }
    if (this.isStudentView) {
      redirectUrl = `student/parent-profile/${parentId}`;
    }
    if (isMotherSelected) {
      redirectUrl = redirectUrl.concat(`?feature=isMotherSelected`);
    }
    this.router.navigateByUrl(redirectUrl);
  }
  getGmailCreditionals() {
    this.parentUserService
      .getGmailCreditionals(this.userProfileId)
      .subscribe((res: any) => {
        this.stdCreditionals = res.data;
      });
  }
  changeVisibility() {
    this.passwordhide = !this.passwordhide;
  }

  extendDueDate(item) {
    if (this.loadingExtendDueDate != '') {
      return;
    }
    if (item) {
      const body = {
        studentId: item.fkStudentId,
        invoiceId: item.invoiceId,
      };
      this.loadingExtendDueDate = item.invoiceId;
      this.parentUserService.dueDateExtension(body).subscribe(
        (res: any) => {
          if (res && res['status'] == 'success') {
            this.getBillingInfo(this.studentProfData.rollNo);
            this._snackBar.openFromComponent(CustomSnackBarComponent, {
              data: { message: res['message'] },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['save-snackBar'],
              verticalPosition: 'top',
            });
            this.loadingExtendDueDate = '';
          }
        },
        (error) => {
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: error.error.message },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
          this.loadingExtendDueDate = '';
        },
      );
    }
  }

  downloadReport(academicTermId, studentId) {
    this.loading = true;
    this.studentProfileService
      .getResultReport(academicTermId, studentId)
      .subscribe(
        (res: any) => {
          this.loading = false;
          if (Object.keys(res.data).length !== 0) {
            const pdfSource = res.data.resultUrl;
            const newWindow = window.open(pdfSource, '_blank');
            if (!newWindow) {
              alert(
                'A pop-up blocker prevented opening the document. Please allow pop-ups and try again.',
              );
            }
            this.analyticsService.trackViewResult({
              studentId,
            });
          } else {
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: res.message },
              horizontalPosition: 'center',
              duration: 2000,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
          }
        },
        (err) => {
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: err.error.message },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
          this.loading = false;
        },
      );
  }

  getStructureTimetable() {
    const url = `branch/${this.studentProfData.branch.id}/grade/${this.studentProfData.grade.id}/section/${this.studentProfData.section.id}`;
    this.StructureTimetable.getByParamsData(url).subscribe(
      (res: any) => {
        this.timetableData = [];
        if (res.data) {
          this.timetableData.push(res.data);
        }
        this.loading = false;
        this.showTimeTable = true;
      },
      (err) => {
        this.loading = false;
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
      },
    );
  }

  getStudentFlexibleTimetable() {
    this.loadingFlexibleTimetable = true;
    this.flexibleTimetableService
      .getStudentTimetable(this.studentId)
      .subscribe((res: any) => {
        this.flexibleTimetableData = res.data && res.data.days;
        this.loadingFlexibleTimetable = false;
        this.showtimetable = true;
      });
  }
}
