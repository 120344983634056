import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-admission-shared-header',
  templateUrl: './admission-shared-header.component.html',
  styleUrls: ['./admission-shared-header.component.scss'],
})
export class AdmissionSharedHeaderComponent {
  @Input() inputData;
  @Input() title: string;
}
